// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2019 = [
  "1/5/2019",
  "1/12/2019",
  "1/19/2019",
  "1/26/2019",
  "2/2/2019",
  "2/9/2019",
  "2/16/2019",
  "2/23/2019",
  "3/2/2019",
  "3/9/2019",
  "3/16/2019",
  "3/23/2019",
  "3/30/2019",
  "4/6/2019",
  "4/13/2019",
  "4/20/2019",
  "4/27/2019",
  "5/4/2019",
  "5/11/2019",
  "5/18/2019",
  "5/25/2019",
  "6/1/2019",
  "6/8/2019",
  "6/15/2019",
  "6/22/2019",
  "6/29/2019",
  "7/6/2019",
  "7/13/2019",
  "7/20/2019",
  "7/27/2019",
  "8/3/2019",
  "8/10/2019",
  "8/17/2019",
  "8/24/2019",
  "8/31/2019",
  "9/7/2019",
  "9/14/2019",
  "9/21/2019",
  "9/28/2019",
  "10/5/2019",
  "10/12/2019",
  "10/19/2019",
  "10/26/2019",
  "11/2/2019",
  "11/9/2019",
  "11/16/2019",
  "11/23/2019",
  "11/30/2019",
  "12/7/2019",
  "12/14/2019",
  "12/21/2019",
  "12/28/2019"
];
