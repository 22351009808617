// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2016 = [
  "1/9/2016",
  "1/16/2016",
  "1/23/2016",
  "1/30/2016",
  "2/6/2016",
  "2/13/2016",
  "2/20/2016",
  "2/27/2016",
  "3/5/2016",
  "3/12/2016",
  "3/19/2016",
  "3/26/2016",
  "4/2/2016",
  "4/9/2016",
  "4/16/2016",
  "4/23/2016",
  "4/30/2016",
  "5/7/2016",
  "5/14/2016",
  "5/21/2016",
  "5/28/2016",
  "6/4/2016",
  "6/11/2016",
  "6/18/2016",
  "6/25/2016",
  "7/2/2016",
  "7/9/2016",
  "7/16/2016",
  "7/23/2016",
  "7/30/2016",
  "8/6/2016",
  "8/13/2016",
  "8/20/2016",
  "8/27/2016",
  "9/3/2016",
  "9/10/2016",
  "9/17/2016",
  "9/24/2016",
  "10/1/2016",
  "10/8/2016",
  "10/15/2016",
  "10/22/2016",
  "10/29/2016",
  "11/5/2016",
  "11/12/2016",
  "11/19/2016",
  "11/26/2016",
  "12/3/2016",
  "12/10/2016",
  "12/17/2016",
  "12/24/2016",
  "12/31/2016"
];
