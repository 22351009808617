import { ReactElement } from "react";
import random from "lodash/random";

const decamelize = (str: string, separator?: string) => {
  separator = typeof separator === "undefined" ? "_" : separator;

  return str
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .toLowerCase();
};

export const toHtmlId = (value: string | ReactElement) => {
  return typeof value === "string" ? value.replace(/\s+/g, "-") : random().toString();
};

export const humanize = (string: string) => {
  string = decamelize(string);
  string = string
    .toLowerCase()
    .replace(/[_-]+/g, " ")
    .replace(/\s{2,}/g, " ")
    .trim();
  string = string.charAt(0).toUpperCase() + string.slice(1);

  return string;
};

export const titleize = (str: string) => {
  // Exclude articles and such from capitalization
  const regex = /(^|\b(?!(and|at|the|for|to|but|by|of|or|nor|for|so|yet)\b))\w+/g;
  return str.toLowerCase().replace(regex, (s: string) => s[0]?.toUpperCase() + s.slice(1));
};

export const extractParentheticalText = (string?: string | null) => {
  if (!string) return "";
  const match = string.match(/\(([^()]*)\)/);
  return match ? match[1] : null;
};

export const capitalizeFirstLetter = (string?: string | null) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const splitStringByCharacterCount = (str: string, maxChars: number): string[] => {
  const words = str.split(" "); // Split the string into words
  const result: string[] = [];
  let currentPart = "";

  for (const word of words) {
    if ((currentPart + word).length <= maxChars) {
      currentPart += (currentPart ? " " : "") + word;
    } else {
      result.push(currentPart);
      currentPart = word;
    }
  }

  if (currentPart) {
    result.push(currentPart);
  }

  return result;
};
