// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2017 = [
  "1/7/2017",
  "1/14/2017",
  "1/21/2017",
  "1/28/2017",
  "2/4/2017",
  "2/11/2017",
  "2/18/2017",
  "2/25/2017",
  "3/4/2017",
  "3/11/2017",
  "3/18/2017",
  "3/25/2017",
  "4/1/2017",
  "4/8/2017",
  "4/15/2017",
  "4/22/2017",
  "4/29/2017",
  "5/6/2017",
  "5/13/2017",
  "5/20/2017",
  "5/27/2017",
  "6/3/2017",
  "6/10/2017",
  "6/17/2017",
  "6/24/2017",
  "7/1/2017",
  "7/8/2017",
  "7/15/2017",
  "7/22/2017",
  "7/29/2017",
  "8/5/2017",
  "8/12/2017",
  "8/19/2017",
  "8/26/2017",
  "9/2/2017",
  "9/9/2017",
  "9/16/2017",
  "9/23/2017",
  "9/30/2017",
  "10/7/2017",
  "10/14/2017",
  "10/21/2017",
  "10/28/2017",
  "11/4/2017",
  "11/11/2017",
  "11/18/2017",
  "11/25/2017",
  "12/2/2017",
  "12/9/2017",
  "12/16/2017",
  "12/23/2017",
  "12/30/2017"
];
