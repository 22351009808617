export const MhcStatUnitEnum = {
  AdverseChildhoodExperiences: "adverse_childhood_experiences",
  Composite: "composite",
  Count: "count",
  CrudeRate: "crude_rate",
  Days: "days",
  Deaths: "deaths",
  DefinedByAnalyte: "defined_by_analyte",
  Degrees: "degrees",
  DegreesCelsius: "degrees_celsius",
  DegreesFarenheit: "degrees_farenheit",
  Dollars: "dollars",
  Doses: "doses",
  Grams: "grams",
  Hospitalizations: "hospitalizations",
  Kits: "kits",
  MicrogramsPerCubicMeterOfAir: "micrograms_per_cubic_meter_of_air",
  MilesPerHour: "miles_per_hour",
  Nights: "nights",
  None: "none",
  People: "people",
  PeoplePerSquareKilometer: "people_per_square_kilometer",
  PeoplePerSquareMile: "people_per_square_mile",
  Percent: "percent",
  Ppb: "ppb",
  Ppm: "ppm",
  Rate: "rate",
  Tests: "tests",
  Years: "years"
} as const;

export type MhcStatUnitEnum = (typeof MhcStatUnitEnum)[keyof typeof MhcStatUnitEnum] | string;
