// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2022 = [
  "1/8/2022",
  "1/15/2022",
  "1/22/2022",
  "1/29/2022",
  "2/5/2022",
  "2/12/2022",
  "2/19/2022",
  "2/26/2022",
  "3/5/2022",
  "3/12/2022",
  "3/19/2022",
  "3/26/2022",
  "4/2/2022",
  "4/9/2022",
  "4/16/2022",
  "4/23/2022",
  "4/30/2022",
  "5/7/2022",
  "5/14/2022",
  "5/21/2022",
  "5/28/2022",
  "6/4/2022",
  "6/11/2022",
  "6/18/2022",
  "6/25/2022",
  "7/2/2022",
  "7/9/2022",
  "7/16/2022",
  "7/23/2022",
  "7/30/2022",
  "8/6/2022",
  "8/13/2022",
  "8/20/2022",
  "8/27/2022",
  "9/3/2022",
  "9/10/2022",
  "9/17/2022",
  "9/24/2022",
  "10/1/2022",
  "10/8/2022",
  "10/15/2022",
  "10/22/2022",
  "10/29/2022",
  "11/5/2022",
  "11/12/2022",
  "11/19/2022",
  "11/26/2022",
  "12/3/2022",
  "12/10/2022",
  "12/17/2022",
  "12/24/2022",
  "12/31/2022"
];
