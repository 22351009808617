// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2024 = [
  "1/6/2024",
  "1/13/2024",
  "1/20/2024",
  "1/27/2024",
  "2/3/2024",
  "2/10/2024",
  "2/17/2024",
  "2/24/2024",
  "3/2/2024",
  "3/9/2024",
  "3/16/2024",
  "3/23/2024",
  "3/30/2024",
  "4/6/2024",
  "4/13/2024",
  "4/20/2024",
  "4/27/2024",
  "5/4/2024",
  "5/11/2024",
  "5/18/2024",
  "5/25/2024",
  "6/1/2024",
  "6/8/2024",
  "6/15/2024",
  "6/22/2024",
  "6/29/2024",
  "7/6/2024",
  "7/13/2024",
  "7/20/2024",
  "7/27/2024",
  "8/3/2024",
  "8/10/2024",
  "8/17/2024",
  "8/24/2024",
  "8/31/2024",
  "9/7/2024",
  "9/14/2024",
  "9/21/2024",
  "9/28/2024",
  "10/5/2024",
  "10/12/2024",
  "10/19/2024",
  "10/26/2024",
  "11/2/2024",
  "11/9/2024",
  "11/16/2024",
  "11/23/2024",
  "11/30/2024",
  "12/7/2024",
  "12/14/2024",
  "12/21/2024",
  "12/28/2024"
];
