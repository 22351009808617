// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2023 = [
  "1/7/2023",
  "1/14/2023",
  "1/21/2023",
  "1/28/2023",
  "2/4/2023",
  "2/11/2023",
  "2/18/2023",
  "2/25/2023",
  "3/4/2023",
  "3/11/2023",
  "3/18/2023",
  "3/25/2023",
  "4/1/2023",
  "4/8/2023",
  "4/15/2023",
  "4/22/2023",
  "4/29/2023",
  "5/6/2023",
  "5/13/2023",
  "5/20/2023",
  "5/27/2023",
  "6/3/2023",
  "6/10/2023",
  "6/17/2023",
  "6/24/2023",
  "7/1/2023",
  "7/8/2023",
  "7/15/2023",
  "7/22/2023",
  "7/29/2023",
  "8/5/2023",
  "8/12/2023",
  "8/19/2023",
  "8/26/2023",
  "9/2/2023",
  "9/9/2023",
  "9/16/2023",
  "9/23/2023",
  "9/30/2023",
  "10/7/2023",
  "10/14/2023",
  "10/21/2023",
  "10/28/2023",
  "11/4/2023",
  "11/11/2023",
  "11/18/2023",
  "11/25/2023",
  "12/2/2023",
  "12/9/2023",
  "12/16/2023",
  "12/23/2023",
  "12/30/2023"
];
