import { getUtcDateFromString } from "common/util/utcDateFromString";

export const utcSecondsToDate = (utcSeconds: number): Date => {
  const d = new Date(0);
  d.setUTCSeconds(utcSeconds + d.getTimezoneOffset() * 60 * 1000);
  return d;
};

export const getYearFromIsoString = (str: string | undefined) => {
  if (!str) return undefined;
  try {
    return (getUtcDateFromString(str) ?? undefined)?.getFullYear();
  } catch {
    return undefined;
  }
};
