import { logError } from "common/util/consoleHelpers";

import { KNOWN_MMWR_YEARS, MMWR_PER_YEAR } from "./MMWRs";

interface BaseGetMmwrWeekDatesProps {
  year: KNOWN_MMWR_YEARS;
  searchYear?: string;
}

interface GetMmwrWeekDatesFromWeekProps extends BaseGetMmwrWeekDatesProps {
  week: number;
  month?: never;
  day?: never;
  searchBy: "Week";
}

interface GetMmwrWeekDatesFromDayMonthProps extends BaseGetMmwrWeekDatesProps {
  week?: never;
  month: number;
  day: number;
  searchBy: "DayMonth";
}

export interface GetMmwrWeekDatesReturn {
  from: string;
  week: number;
  to: string;
}

type Props = GetMmwrWeekDatesFromWeekProps | GetMmwrWeekDatesFromDayMonthProps;

export const getMmwrWeekDates = ({
  year,
  week,
  month,
  day,
  searchBy,
  searchYear
}: Props): GetMmwrWeekDatesReturn | undefined => {
  const mmwrReference = MMWR_PER_YEAR[`${year}`];
  if (mmwrReference === undefined) {
    logError(`mmwrReference is undefined: ${year}`);
    return undefined;
  }
  if (searchBy === "Week") {
    if (week > mmwrReference.length) {
      logError(`WEEK EXCEDES KNOWN WEEKS FOR YEAR: ${year}`);
      return undefined;
    }
    const toWeek = mmwrReference[week - 1];
    if (toWeek !== undefined) {
      const initialDate: string = (() => {
        const minusSevenDays = new Date(toWeek);
        minusSevenDays.setDate(minusSevenDays.getDate() - 6);
        return `${
          minusSevenDays.getMonth() + 1
        }/${minusSevenDays.getDate()}/${minusSevenDays.getFullYear()}`;
      })();
      return {
        from: initialDate ?? null,
        to: toWeek ?? null,
        week: week ?? null
      };
    }
  }
  if (searchBy === "DayMonth") {
    let searchDate = new Date(`${month}/${day}/${searchYear !== undefined ? searchYear : year}`);
    let initialString: string | undefined = undefined;
    let finalString: string | undefined = undefined;
    let foundWeek: number | undefined = undefined;
    for (let i = 0; i < mmwrReference.length; i++) {
      const date = mmwrReference[i];
      if (date === undefined) {
        continue;
      }
      const finalDate = new Date(date);
      if (`${finalDate.getFullYear()}` !== year && month !== undefined && day !== undefined) {
        searchDate = new Date(`${month}/${day}/${finalDate.getFullYear()}`);
      }
      const initialDate: Date = (() => {
        const minusSevenDays = new Date(date);
        minusSevenDays.setDate(minusSevenDays.getDate() - 6);
        return minusSevenDays;
      })();
      let within = false;
      within = searchDate <= finalDate && searchDate >= initialDate;
      if (within === true) {
        finalString = date;
        initialString = `${
          initialDate.getMonth() + 1
        }/${initialDate.getDate()}/${initialDate.getFullYear()}`;
        foundWeek = i + 1;
        break;
      }
    }
    if (foundWeek !== undefined && initialString !== undefined && finalString !== undefined) {
      return {
        from: initialString,
        to: finalString,
        week: foundWeek
      };
    } else {
      logError(
        `Week and initial string are undefined: ${foundWeek ?? "NA"} ${initialString ?? "NA"}`
      );
    }
  }
  return undefined;
};

export const getMmwrFromStringDate = (props: Props): Date | undefined => {
  const dates = getMmwrWeekDates(props);
  return dates?.from ? new Date(dates?.from) : undefined;
};

export const getMmwrToStringDate = (props: Props): Date | undefined => {
  const dates = getMmwrWeekDates(props);
  return dates?.to ? new Date(dates?.to) : undefined;
};
