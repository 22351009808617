import { MhcStatUnitEnum } from "common/util/types";

import { formatCurrency } from "./formatCurrency";
import { formatNumberWithDelimiter } from "./formatNumberWithDelimiter";
import { formatPercentage } from "./formatPercentage";

type NumberInput = number | null | undefined;
interface FormatValueByUnitOptions<T> {
  value: T;
  unit?: MhcStatUnitEnum | null;
  precision?: number;
  isPercent?: boolean;
}
type ReturnType<T> = T extends number ? string : undefined;

const innerFormatByUnit = (
  value: number,
  precision: number,
  unit: MhcStatUnitEnum,
  isPercent = false
): string => {
  if (isPercent) return formatPercentage(value, precision);
  if (!MhcStatUnitEnum) return formatNumberWithDelimiter(value, precision);
  switch (unit || MhcStatUnitEnum.None) {
    case MhcStatUnitEnum.Dollars:
      return formatCurrency(value, precision);
    case MhcStatUnitEnum.Percent:
      return formatPercentage(value, precision);
    default:
      return formatNumberWithDelimiter(value, precision);
  }
};

// cannot return narrow type
// https://github.com/microsoft/TypeScript/issues/33912
export const formatValueByUnit = <T extends NumberInput>({
  value,
  unit,
  precision = 0,
  isPercent
}: FormatValueByUnitOptions<T>): ReturnType<T> => {
  if (value == undefined || value === null) {
    return undefined as ReturnType<T>;
  }
  return innerFormatByUnit(
    value,
    precision,
    unit || MhcStatUnitEnum.Count,
    isPercent
  ) as ReturnType<T>;
};
