// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2020 = [
  "1/4/2020",
  "1/11/2020",
  "1/18/2020",
  "1/25/2020",
  "2/1/2020",
  "2/8/2020",
  "2/15/2020",
  "2/22/2020",
  "2/29/2020",
  "3/7/2020",
  "3/14/2020",
  "3/21/2020",
  "3/28/2020",
  "4/4/2020",
  "4/11/2020",
  "4/18/2020",
  "4/25/2020",
  "5/2/2020",
  "5/9/2020",
  "5/16/2020",
  "5/23/2020",
  "5/30/2020",
  "6/6/2020",
  "6/13/2020",
  "6/20/2020",
  "6/27/2020",
  "7/4/2020",
  "7/11/2020",
  "7/18/2020",
  "7/25/2020",
  "8/1/2020",
  "8/8/2020",
  "8/15/2020",
  "8/22/2020",
  "8/29/2020",
  "9/5/2020",
  "9/12/2020",
  "9/19/2020",
  "9/26/2020",
  "10/3/2020",
  "10/10/2020",
  "10/17/2020",
  "10/24/2020",
  "10/31/2020",
  "11/7/2020",
  "11/14/2020",
  "11/21/2020",
  "11/28/2020",
  "12/5/2020",
  "12/12/2020",
  "12/19/2020",
  "12/26/2020",
  "1/2/2021"
];
