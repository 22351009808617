import { MMWR_2016 } from "./2016";
import { MMWR_2017 } from "./2017";
import { MMWR_2018 } from "./2018";
import { MMWR_2019 } from "./2019";
import { MMWR_2020 } from "./2020";
import { MMWR_2021 } from "./2021";
import { MMWR_2022 } from "./2022";
import { MMWR_2023 } from "./2023";
import { MMWR_2024 } from "./2024";
import { MMWR_2025 } from "./2025";

export const KnownMmwrYears = [
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025"
] as const;

export type KNOWN_MMWR_YEARS = (typeof KnownMmwrYears)[number];

export const MMWR_PER_YEAR: { [key in KNOWN_MMWR_YEARS]: string[] } = {
  "2016": MMWR_2016,
  "2017": MMWR_2017,
  "2018": MMWR_2018,
  "2019": MMWR_2019,
  "2020": MMWR_2020,
  "2021": MMWR_2021,
  "2022": MMWR_2022,
  "2023": MMWR_2023,
  "2024": MMWR_2024,
  "2025": MMWR_2025
};
