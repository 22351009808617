// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2021 = [
  "1/9/2021",
  "1/16/2021",
  "1/23/2021",
  "1/30/2021",
  "2/6/2021",
  "2/13/2021",
  "2/20/2021",
  "2/27/2021",
  "3/6/2021",
  "3/13/2021",
  "3/20/2021",
  "3/27/2021",
  "4/3/2021",
  "4/10/2021",
  "4/17/2021",
  "4/24/2021",
  "5/1/2021",
  "5/8/2021",
  "5/15/2021",
  "5/22/2021",
  "5/29/2021",
  "6/5/2021",
  "6/12/2021",
  "6/19/2021",
  "6/26/2021",
  "7/3/2021",
  "7/10/2021",
  "7/17/2021",
  "7/24/2021",
  "7/31/2021",
  "8/7/2021",
  "8/14/2021",
  "8/21/2021",
  "8/28/2021",
  "9/4/2021",
  "9/11/2021",
  "9/18/2021",
  "9/25/2021",
  "10/2/2021",
  "10/9/2021",
  "10/16/2021",
  "10/23/2021",
  "10/30/2021",
  "11/6/2021",
  "11/13/2021",
  "11/20/2021",
  "11/27/2021",
  "12/4/2021",
  "12/11/2021",
  "12/18/2021",
  "12/25/2021",
  "1/1/2022"
];
