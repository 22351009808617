// The MMWR week is the week of the epidemiologic year for which the
// National Notifiable Diseases Surveillance System (NNDSS) disease report is
// assigned for the purposes of MMWR disease incidence reporting and publishing.
// When a notifiable disease case is reported to the state or local health
// department, it is assigned (coded) to an MMWR Week. (1)
//
// MMWR Week does not fall on the same dates every year. MMWR weeks last
// from Sunday through Saturday and usually range from 1 to 52. Depending
// on how the days of the week fall across years, it is possible to occasionally
// have a Week 53. Week #1 of an MMWR year is the first week of the year that has
// at least four days in the calendar year.
export const MMWR_2018 = [
  "1/6/2018",
  "1/13/2018",
  "1/20/2018",
  "1/27/2018",
  "2/3/2018",
  "2/10/2018",
  "2/17/2018",
  "2/24/2018",
  "3/3/2018",
  "3/10/2018",
  "3/17/2018",
  "3/24/2018",
  "3/31/2018",
  "4/7/2018",
  "4/14/2018",
  "4/21/2018",
  "4/28/2018",
  "5/5/2018",
  "5/12/2018",
  "5/19/2018",
  "5/26/2018",
  "6/2/2018",
  "6/9/2018",
  "6/16/2018",
  "6/23/2018",
  "6/30/2018",
  "7/7/2018",
  "7/14/2018",
  "7/21/2018",
  "7/28/2018",
  "8/4/2018",
  "8/11/2018",
  "8/18/2018",
  "8/25/2018",
  "9/1/2018",
  "9/8/2018",
  "9/15/2018",
  "9/22/2018",
  "9/29/2018",
  "10/6/2018",
  "10/13/2018",
  "10/20/2018",
  "10/27/2018",
  "11/3/2018",
  "11/10/2018",
  "11/17/2018",
  "11/24/2018",
  "12/1/2018",
  "12/8/2018",
  "12/15/2018",
  "12/22/2018",
  "12/29/2018"
];
