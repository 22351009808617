import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { KNOWN_MMWR_YEARS } from "modules/Flu/util/fetchingFunctions/MMWRs";
import { getMmwrWeekDates } from "modules/Flu/util/fetchingFunctions/mmwrWeek";

import { utcSecondsToDate } from "../helpers";

export type SpecialDateFormat = "mmwr" | "weekRange";

interface Options<T> {
  value: T;
  granularity?: MhcTimeSeriesGranularityEnum | null;
  specialFormat?: SpecialDateFormat;
}

type ReturnType<T, V> = T extends V ? string : undefined;

const monthNames = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec"
];

/**
 * Formats date as week.
 * Handles formatting for mmwr weeks if specialFormat is set to `mmwr`.
 *
 * @param params
 * @param params.value  - date (as Date) to format
 * @param params.specialFormat  - indicates special formatting if necessary
 *
 * @returns formatted date based on granularity
 *
 * @see SpecialDateFormat
 * @see KNOWN_MMWR_YEARS
 * @see getMmwrWeekDates
 *
 */
export const formatWeekDate = ({
  value,
  specialFormat
}: {
  value: Date;
  specialFormat?: SpecialDateFormat;
}): string => {
  const standardWeekFormat = (value: Date) => {
    return `${`${value.getMonth() + 1}`}/${`${value.getDate()}`}/${value.getFullYear()}`;
  };
  if (specialFormat === "mmwr") {
    const mmwr = getMmwrWeekDates({
      year: `${value.getFullYear()}` as KNOWN_MMWR_YEARS,
      searchBy: "DayMonth",
      day: value.getDate(),
      month: value.getMonth() + 1
    });
    return `Week ${mmwr?.week ?? ""} (${mmwr?.from ?? ""} - ${mmwr?.to ?? ""})`;
  }
  if (specialFormat === "weekRange") {
    const start = new Date(value);
    start.setDate(start.getDate() - 6);
    return `${standardWeekFormat(start)} - ${standardWeekFormat(value)}`;
  }
  return standardWeekFormat(value);
};

/**
 * Formats date based on granularity and special format (if required).
 *
 * @param value - date to format
 * @param granularity - granularity to format date by
 * @param specialFormat - indicates special formatting if necessary
 *
 * @returns Formatted date string based on granularity
 *
 * @see SpecialDateFormat
 * @see MhcTimeSeriesGranularityEnum
 * @see formatWeekDate
 *
 */
const formatDate = (
  value: Date,
  granularity: MhcTimeSeriesGranularityEnum,
  specialFormat?: SpecialDateFormat
) => {
  switch (granularity) {
    case MhcTimeSeriesGranularityEnum.Day:
    case MhcTimeSeriesGranularityEnum.Month:
      return `${monthNames[value.getMonth()] || ""} ${value.getFullYear()}`;
    case MhcTimeSeriesGranularityEnum.Year:
      return String(value.getFullYear());
    case MhcTimeSeriesGranularityEnum.FiveYearWindow:
      return `${value.getFullYear() - 4} - ${value.getFullYear()}`;
    case MhcTimeSeriesGranularityEnum.Week:
      return formatWeekDate({ value, specialFormat });
    default:
      return value.toString();
  }
};

export const formatDateByGranularity = <T extends Date | null | undefined>({
  value,
  granularity,
  specialFormat
}: Options<T>): ReturnType<T, Date> => {
  if (!value || !granularity) {
    return undefined as ReturnType<T, Date>;
  }
  return formatDate(value, granularity, specialFormat) as ReturnType<T, Date>;
};

export const formatSecondsByGranularity = <T extends number | null | undefined>({
  value,
  granularity,
  specialFormat
}: Options<T>): ReturnType<T, number> => {
  if (!value || !granularity) {
    return undefined as ReturnType<T, number>;
  }

  return formatDate(utcSecondsToDate(value), granularity, specialFormat) as ReturnType<T, number>;
};
